import type { Api } from '~/global'
import { defineStore } from 'pinia'

export const useFallbackStore = defineStore('FallbackStore', () => {
    const storesListFallback = ref() as Ref<Api.Responses.Pages.Stores>
    const searchFallback = ref() as Ref<Api.Responses.Pages.SearchResults>

    const setStoresFallback = (data: Api.Responses.Pages.Stores) => {
        storesListFallback.value = data
    }

    const setSearchFallback = (data: Api.Responses.Pages.SearchResults) => {
        searchFallback.value = data
    }

    return {
        storesListFallback,
        searchFallback,
        setSearchFallback,
        setStoresFallback,
    }
})
