<script lang="ts" setup>
import { type PropType } from 'vue'
import { onClickOutside } from '@vueuse/core'
import type { Models } from '~/types/models'
/* import { useRootStore } from '~/store/root' */
import type { Api } from '~~/global'

import { useRootStore } from '~/store/root'
import { useFallbackStore } from '~/store/fallback'

const props = defineProps({
    onOpenHandler: {
        type: Function as PropType<any>,
    },
    defaultStores: {
        type: Array as PropType<Models.Store[]>,
    },
    defaultCategories: {
        type: Array as PropType<Models.Category[]>,
    },
    defaultEvents: {
        type: Array as PropType<Models.Event[]>,
    },
})

const inputsearch = defineModel<string>({ default: '' })
const mounted = ref(false)
const searchMounted = ref(false)
const loading = ref(false)
const timer = ref(null as null | NodeJS.Timeout)

const serverStoresSuggests = ref(null) as Ref<null | Models.Store[]>
const serverCategoriesSuggests = ref(null) as Ref<null | Models.Category[]>
const serverEventsSuggests = ref(null) as Ref<null | Models.Event[]>

const showSuggests = ref(false)
const showSearch = ref(true)
const showToggle = ref(true as boolean | null)

const $input = ref() as Ref<HTMLInputElement>
const $container = ref() as Ref<HTMLDivElement>

onClickOutside($container, () => {
    toggleSearch(false)
})

const Route = useRoute()
const Router = useRouter()

const fullPath = computed(() => Route.fullPath)

watch(fullPath, () => {
    toggleSearch(false)
})

watch(showSearch, (newValue) => {
    if (newValue) {
        document.documentElement.classList.add('is-locked')
    } else {
        document.documentElement.classList.remove('is-locked')
    }
})

watch(showSuggests, (newValue) => {
    if (newValue) {
        document.documentElement.classList.add('is-locked')
    } else {
        document.documentElement.classList.remove('is-locked')
    }
})

const { $bp, $lang } = useNuxtApp()

const mobile = computed(() => {
    return ($bp && ($bp.mb || $bp.xs || $bp.sm)) || false
})

const storesSuggests = computed(() => {
    return serverStoresSuggests.value || props.defaultStores || []
})

const categoriesSuggests = computed(() => {
    return serverCategoriesSuggests.value || props.defaultCategories || []
})

const eventsSuggests = computed(() => {
    return serverEventsSuggests.value || props.defaultEvents || []
})

const toggleSearch = (show: boolean) => {
    if (mobile.value) {
        if (show) {
            if (!showSearch.value && !showSuggests.value) {
                showToggle.value = false
                props.onOpenHandler(false)
                showSearch.value = true
                $input.value?.focus()
                showSuggests.value = true
            }
        } else {
            $input.value?.blur()
            showSuggests.value = false
            showSearch.value = false
            serverStoresSuggests.value = null
            serverEventsSuggests.value = null
            serverCategoriesSuggests.value = null
            showToggle.value = true
            props.onOpenHandler(true)
        }
    } else if (show) {
        showSuggests.value = true
    } else {
        $input.value?.blur()
        showSuggests.value = false
        serverStoresSuggests.value = null
        serverEventsSuggests.value = null
        serverCategoriesSuggests.value = null
    }
}

watch(
    Route,
    () => {
        if (Route.path !== $lang.routes.search) {
            inputsearch.value = ''
        }
        !!showSearch.value && toggleSearch(false)
    },
    { deep: true },
)

onMounted(() => {
    showToggle.value = $bp.mb || $bp.xs || $bp.sm

    showSearch.value = !showToggle.value

    setTimeout(() => (mounted.value = true), 1000)

    searchMounted.value = true
})

const { buildHeaders, baseUrl, endpoints } = useApiClient()

const onInput = (e: Event) => {
    const term = inputsearch.value as string

    !showSuggests.value && toggleSearch(true)

    if (term.trim().length > 1) {
        timer.value && clearTimeout(timer.value)

        timer.value = setTimeout(async () => {
            loading.value = true

            try {
                const result = await $fetch<Api.Responses.General.Search>(
                    `${endpoints.general.search.path}?search=${term}`,
                    {
                        method: 'GET',
                        headers: buildHeaders(),
                        baseURL: baseUrl,
                    },
                )

                if (result && result.feedback === 'data_success') {
                    serverStoresSuggests.value = result.data.stores

                    serverCategoriesSuggests.value = result.data.categories

                    serverEventsSuggests.value = result.data.events
                }
            } catch (e) {}

            loading.value = false

            timer.value = null
        }, 600)
    } else {
        serverStoresSuggests.value = null

        serverCategoriesSuggests.value = null
    }
}

const RootStore = useRootStore()
const FallbackStore = useFallbackStore()

const onSubmit = async () => {
    if (timer.value) {
        clearTimeout(timer.value)

        timer.value = null
    }

    if (Route.query.q === inputsearch.value) return

    if (inputsearch.value && (inputsearch.value as string).length > 1) {
        const searchResult = await $fetch<Api.Responses.Pages.SearchResults>(endpoints.pages.search.path, {
            method: 'GET',
            headers: buildHeaders(),
            baseURL: baseUrl,
            params: { search: inputsearch.value },
        }).catch((err) => {
            console.log('Error: ', err)
        })

        if (searchResult) {
            if (searchResult.feedback === 'internal_redirect') {
                if (searchResult.data !== Route.path) {
                    Router.push({ path: searchResult.data })
                }
            } else {
                FallbackStore.setSearchFallback(searchResult)

                Router.push({ path: '/' + $lang.routes.search, query: { q: inputsearch.value as string } })
            }
        } else {
            RootStore.setSiteNotification({
                duration: 5000,
                text: $lang.components.layoutHeaderSearch.error,
                type: 'error',
                dismissButtonText: $lang.components.layoutHeaderSearch.okay,
            })
        }
    } else {
        RootStore.setSiteNotification({
            duration: 5000,
            text: $lang.components.layoutHeaderSearch.must_min_2_chars,
            type: 'help',
            dismissButtonText: $lang.components.layoutHeaderSearch.okay,
        })
    }
}
</script>

<template>
    <div
        ref="$container"
        class="header-component-search"
        :class="{
            'is-loading': !mounted,
            'full-version': searchMounted && showSearch,
        }"
    >
        <button
            v-if="showToggle"
            ref="toggle"
            type="button"
            class="header-component-search__toggle"
            :class="$isMega ? 'ml-auto' : ''"
            @click="() => toggleSearch(true)"
        >
            <img :src="$assets.white.search" alt="icon" />
        </button>

        <form v-if="showSearch" class="header-component-search__box" @submit.prevent="onSubmit">
            <button
                class="header-component-search__search-button"
                type="submit"
                :title="$lang.components.layoutHeaderSearch.search"
            >
                <img
                    v-show="loading"
                    :src="$assets.primary.loading"
                    height="17px"
                    width="17px"
                    alt="icon"
                    class="spin"
                />
                <img v-show="!loading" :src="$assets.primary.search" height="17" width="17" alt="icon" />
            </button>

            <input
                ref="$input"
                v-model="inputsearch"
                type="text"
                autocomplete="off"
                name="Buscar"
                :placeholder="`${$lang.components.layoutHeaderSearch.search}...`"
                :class="{ focused: showSuggests }"
                @click="() => toggleSearch(true)"
                @input="onInput"
            />

            <button
                v-if="showSuggests"
                class="header-component-search__close-button"
                type="button"
                title="Cerrar"
                @click="toggleSearch(false)"
            >
                <img :src="$assets.primary.close" width="17" alt="icon" />
            </button>

            <div ref="$suggestions" v-if="showSuggests" class="header-component-search__suggestions">
                <HeaderSuggestions
                    :stores="storesSuggests"
                    :categories="categoriesSuggests"
                    :events="eventsSuggests"
                />
            </div>
        </form>
    </div>
</template>

<style lang="postcss" scoped>
.header-component-search {
    @media (max-width: 640px) {
        &.full-version {
            position: absolute;
            top: 50%;
            right: 8px;
            transform: translateY(-50%);
            width: calc(100% - 16px);
        }
    }
    &.is-loading {
        .header-component-search__box {
            @apply hidden md:block;
        }
    }
    &__toggle {
        @apply block h-10 w-10 rounded-lg bg-black bg-opacity-20 md:hidden;
        img {
            @apply mx-auto block h-5 w-5;
        }
    }
    &__box {
        @apply relative z-10 flex xs:ml-auto md:max-w-[310px];
        input {
            @apply relative z-10 h-10 w-full rounded-lg border border-gray-200 px-8 text-sm focus:border-site-primary focus:ring-site-primary;
        }
    }
    &__search-button {
        @apply absolute left-1 top-1/2 z-40 block h-7 w-7 -translate-y-1/2 transform;
        img {
            @apply h-full w-full object-contain p-1;
        }
    }

    &__close-button {
        @apply absolute right-1 top-1/2 z-[140] block h-7 w-7 -translate-y-1/2 transform;

        img {
            @apply h-full w-full object-contain p-2;
        }
    }

    &__suggestions {
        @apply absolute left-0 right-0;
        top: calc(100% - 10px);
    }
}
@keyframes fast-spin {
    to {
        transform: rotate(360deg);
    }
}
.spin {
    animation: fast-spin 400ms linear infinite;
}
</style>
