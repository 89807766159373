<template>
    <div class="layout-header-suggestions">
        <section v-if="stores && stores.length > 0" class="layout-header-suggestions__group">
            <h4 class="layout-header-suggestions__title">
                {{ $lang.components.layoutHeaderSuggestions.stores }}
            </h4>
            <ul v-if="stores.length > 0" class="layout-header-suggestions__list">
                <li v-for="store in stores" :key="store.id" class="layout-header-suggestions__item">
                    <NuxtLink
                        no-prefetch
                        :to="`${!$isCupohosting ? `/${$lang.routes.brands}` : ''}/${store.slug}`"
                        rel="follow"
                    >
                        <div class="image-wrapper">
                            <img
                                v-if="store.image"
                                :src="store.image.url"
                                :title="store.name"
                                :alt="store.name"
                                class="h-full w-full object-contain"
                            />
                        </div>
                        <div class="texts-wrapper">
                            <p>{{ store.name }}</p>
                            <p>
                                {{ store.all_count }}
                                {{
                                    store.all_count === 1
                                        ? $lang.components.layoutHeaderSuggestions.discount
                                        : $lang.components.layoutHeaderSuggestions.discounts
                                }}
                            </p>
                        </div>
                    </NuxtLink>
                </li>
            </ul>
        </section>
        <section v-if="events && events.length > 0" class="layout-header-suggestions__group">
            <h4 class="layout-header-suggestions__title">
                {{ $lang.components.layoutHeaderSuggestions.events }}
            </h4>
            <ul class="layout-header-suggestions__list">
                <li v-for="event in events" :key="event.id" class="layout-header-suggestions__item">
                    <NuxtLink no-prefetch :to="`/${$lang.routes.events}/${event.slug}`" rel="follow">
                        <div class="image-wrapper">
                            <img
                                v-if="event.image"
                                :src="event.image.url"
                                :alt="event.title"
                                :title="event.title"
                                class="h-full w-full object-contain"
                            />
                        </div>
                        <div class="texts-wrapper">
                            <p>{{ event.title }}</p>
                            <!-- <p>
                                {{ event.all_count }}
                                {{
                                    event.all_count === 1
                                        ? $lang.components.layoutHeaderSuggestions.event
                                        : $lang.components.layoutHeaderSuggestions.events
                                }}
                            </p> -->
                        </div>
                    </NuxtLink>
                </li>
            </ul>
        </section>
        <section
            v-if="
                !stores || !categories || !events || (!stores.length && !stores.length && !categories.length)
            "
            class="layout-header-suggestions__group-notfound"
        >
            <h4 class="layout-header-suggestions__title-notfound">
                {{ $lang.components.layoutHeaderSuggestions.no_results }}
            </h4>
        </section>
    </div>
</template>

<script lang="ts">
import { type PropType, defineComponent } from 'vue'
import type { Models } from '~/types/models'

export default defineComponent({
    name: 'LayoutHeaderSuggestions',
    props: {
        stores: { type: Array as PropType<Models.Store[]> },
        categories: { type: Array as PropType<Models.Category[]> },
        events: { type: Array as PropType<Models.Event[]> },
    },
})
</script>

<style lang="postcss" scoped>
.layout-header-suggestions {
    @apply overflow-y-auto rounded-b-lg border border-t-0 border-gray-200 bg-white pb-1.5 pt-3 shadow-lg sm:space-y-3 sm:pb-2 sm:pt-4;
    max-height: 80vh;
    &__group {
        @apply px-1.5 sm:px-2.5;
        &:not(:last-child) {
            @apply border-b border-gray-200 pb-3;
        }
    }
    &__group-notfound {
        @apply px-1.5 sm:px-2.5;
    }
    &__title {
        @apply my-2 text-sm font-bold text-gray-800;
    }
    &__title-notfound {
        @apply my-4 text-center text-sm font-bold text-gray-800;
    }
    &__list {
        @apply space-y-2;
    }
    &__item {
        a {
            @apply flex items-center space-x-2 rounded-lg py-2 transition-colors sm:space-x-2 lg:px-2.5 lg:ring-opacity-30 lg:hover:bg-gray-100 lg:hover:ring-1 lg:hover:ring-gray-200;
            .image-wrapper {
                @apply h-9 w-9 sm:h-12 sm:w-12;
            }
            .texts-wrapper {
                @apply text-sm leading-5 text-gray-800;
            }
        }
    }
}
</style>
