<template>
    <div class="header-dropdown" ref="menuContext">
        <button
            type="button"
            @click="() => toggle()"
            class="header-dropdown__toggle"
            :class="{ 'lg:bg-black lg:bg-opacity-20': showMenu }"
        >
            <div v-if="toggleIcon" class="header-dropdown__toggle-icon mr-1 hidden lg:block">
                <svg
                    v-if="toggleIcon === 'category'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <path
                        d="M10 3H3V10H10V3Z"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M21 3H14V10H21V3Z"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M21 14H14V21H21V14Z"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M10 14H3V21H10V14Z"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
                <svg
                    v-if="toggleIcon === 'tag'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <g clip-path="url(#clip0_703_1300)">
                        <path
                            d="M7 7H7.01M20.59 13.41L13.42 20.58C13.2343 20.766 13.0137 20.9135 12.7709 21.0141C12.5281 21.1148 12.2678 21.1666 12.005 21.1666C11.7422 21.1666 11.4819 21.1148 11.2391 21.0141C10.9963 20.9135 10.7757 20.766 10.59 20.58L2 12V2H12L20.59 10.59C20.9625 10.9647 21.1716 11.4716 21.1716 12C21.1716 12.5284 20.9625 13.0353 20.59 13.41Z"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_703_1300">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </div>

            <span class="header-dropdown__toggle-text">{{ toggleText }}</span>

            <svg
                class="header-dropdown__toggle-icon ml-1"
                :class="{ 'rotate-180 transform': showMenu }"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
            >
                <path d="M6 9L12 15L18 9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </button>
        <ul v-if="showMenu" class="header-dropdown__options">
            <div v-if="isCouponLink">
                <li class="pl-2">
                    <NuxtLink
                        :to="$lang.routes.coupons"
                        class="font-semibold text-gray-600 lg:hover:underline"
                    >
                        Inicio Cupones</NuxtLink
                    >
                </li>
                <hr class="my-2" />
            </div>
            <li v-for="(option, index) in options" :key="index">
                <NuxtLink class="header-dropdown__option-item" v-if="!!option.path" :to="option.path">
                    <picture v-if="option.image">
                        <img :src="option.image" :alt="option.text" />
                    </picture>
                    <span>{{ option.text }}</span>
                </NuxtLink>
                <button
                    class="header-dropdown__option-item"
                    v-else-if="option.handler"
                    type="button"
                    @click="option.handler"
                >
                    <picture v-if="option.image">
                        <img :src="option.image" :alt="option.text" />
                    </picture>
                    <span>{{ option.text }}</span>
                </button>
            </li>
            <div v-if="seeMore">
                <hr class="my-2" />
                <li class="pl-2">
                    <NuxtLink :to="seeMore.path" class="font-semibold text-gray-600 lg:hover:underline">{{
                        seeMore.text
                    }}</NuxtLink>
                </li>
            </div>
        </ul>
    </div>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import { onClickOutside } from '@vueuse/core'

type Option = {
    text: string
    image?: string
    path?: string
    handler?: () => any
}

const props = defineProps({
    toggleText: {
        type: String,
        default: 'Menú',
    },
    toggleIcon: {
        type: String,
    },
    seeMore: {
        type: Object as PropType<{ path: string; text: string }>,
    },
    options: {
        type: Object as PropType<Option[]>,
        default: () => {},
    },
    isCouponLink: {
        type: Boolean,
        default: false,
    },
})

const menuContext = ref() as Ref<HTMLDivElement>

const showMenu = ref(false)

const toggle = (show?: boolean) => {
    if (show !== undefined) {
        showMenu.value = show
    } else {
        showMenu.value = !showMenu.value
    }
}

onClickOutside(menuContext, () => toggle(false))

const Route = useRoute()

const fullPath = computed(() => Route.fullPath)

watch(fullPath, () => {
    toggle(false)
})
</script>

<style lang="postcss">
.header-dropdown {
    @apply relative;
    &__toggle {
        @apply flex w-full items-center justify-between rounded-lg;
    }
    &__toggle-icon {
        @apply h-4 w-4;
        svg {
            @apply h-full w-full stroke-current object-contain;
        }
    }
    &__toggle-text {
        @apply flex-grow text-left;
    }
    &__options {
        @apply w-full py-3 lg:absolute lg:left-1/2 lg:w-max lg:-translate-x-1/2 lg:transform lg:bg-white lg:px-2 lg:shadow-md;
        top: calc(100% + theme('spacing.1'));
        li:not(:last-of-type) {
            @apply mb-2;
        }
    }
    &__option-item {
        @apply flex items-center space-x-4 px-2 py-1 text-gray-800 transition-colors duration-300 lg:hover:bg-black lg:hover:bg-opacity-15;
        span {
            @apply text-lg font-medium lg:text-base lg:font-normal;
        }
        picture {
            @apply block h-10 w-10 overflow-hidden rounded-full border border-gray-200 bg-white;
            img {
                @apply h-full w-full object-contain;
            }
        }
    }
}
</style>
