<template>
    <div class="header-component-menu" ref="$menu">
        <div v-show="showMenu" class="header-component-menu__container" :class="{ loading: !mounted }">
            <div class="header-component-menu__close-menu">
                <p>Menú</p>

                <button type="button" @click="() => toggleMenu(false)">
                    <svg width="12" height="12" class="icon icon--cross">
                        <path
                            xmlns="http://www.w3.org/2000/svg"
                            d="M7 8.4l-4.9 4.9a.948.948 0 01-.7.275.948.948 0 01-.7-.275.948.948 0 01-.275-.7c0-.283.092-.517.275-.7L5.6 7 .7 2.1a.948.948 0 01-.275-.7c0-.283.092-.517.275-.7a.948.948 0 01.7-.275c.283 0 .517.092.7.275L7 5.6 11.9.7a.948.948 0 01.7-.275c.283 0 .517.092.7.275a.948.948 0 01.275.7.948.948 0 01-.275.7L8.4 7l4.9 4.9a.948.948 0 01.275.7.948.948 0 01-.275.7.948.948 0 01-.7.275.948.948 0 01-.7-.275L7 8.4z"
                            transform="translate(1, 1)"
                            fill="currentColor"
                        />
                    </svg>
                </button>
            </div>
            <div class="container">
                <HeaderLink link="home" class="header-component-menu__item" />

                <HeaderDropdown
                    v-if="stores && stores.length"
                    class="header-component-menu__item-dropdown"
                    toggle-icon="tag"
                    toggle-text="Cupones"
                    :options="
                        stores.map((s) => {
                            return {
                                path: $isCupohosting ? `/${s.slug}` : `/${$lang.routes.brands}/${s.slug}`,
                                text: s.name,
                                image: s.image?.url,
                            }
                        })
                    "
                    :see-more="{
                        text: 'Ver más tiendas',
                        path: '/' + $lang.routes.brands,
                    }"
                />

                <HeaderLink link="top25" class="header-component-menu__item" />

                <HeaderLink link="blog" class="header-component-menu__item" />

                <HeaderLink link="sendCoupon" class="header-component-menu__item" />
            </div>
        </div>

        <button
            type="button"
            class="header-component-menu__button"
            :class="{ hide: $isMega, 'is-open': mounted && showMenu }"
            :aria-label="showMenu ? 'Cerrar menú' : 'Abrir menú'"
            @click="() => toggleMenu(!showMenu)"
        >
            <div>
                <span></span>
            </div>
        </button>
    </div>
</template>

<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core'
import { useRootStore } from '~/store/root'
import HeaderLink from './HeaderLink.vue'

const { $lang } = useNuxtApp()

const config = useRuntimeConfig()

const $menu = ref()

const { $bp } = useNuxtApp()

onClickOutside($menu, () => {
    if ($bp.isMobile) {
        toggleMenu(false)
    }
})

const showMenu = ref(true)
const mounted = ref(false)

onMounted(async () => {
    if ($bp.isMobile) {
        showMenu.value = false
    }
    await nextTick()

    mounted.value = true
})

const { $assets } = useNuxtApp()

const RootStore = useRootStore()

const categories = computed(() => RootStore.layoutData?.data.header.menu.categories)

const stores = computed(() => RootStore.layoutData?.data.header.menu.stores)

const generalLinks = computed(() => RootStore.layoutData?.data.header.menu.links)

const toggleMenu = (show: boolean) => {
    showMenu.value = show
}

const Route = useRoute()

const fullPath = computed(() => Route.fullPath)

watch(fullPath, () => {
    if ($bp.isMobile) {
        toggleMenu(false)
    }
})

watch(showMenu, (newValue) => {
    if (newValue) {
        document.documentElement.classList.add('is-locked')
    } else {
        document.documentElement.classList.remove('is-locked')
    }
})
</script>

<style lang="postcss">
.header-component-menu {
    &__container {
        @apply absolute left-0 w-full overflow-y-auto bg-white pb-6 lg:overflow-visible;

        &.loading {
            @apply hidden lg:block;
        }

        top: calc(var(--total-header-height) - var(--headband-height));

        height: calc(100vh - var(--total-header-height) + var(--headband-height));

        @apply lg:static lg:h-auto lg:w-auto lg:bg-transparent lg:py-0;

        .container {
            @apply px-4 lg:flex lg:items-center lg:justify-center lg:px-0;
        }
    }
    &__close-menu {
        @apply container flex h-14 items-center justify-between bg-black bg-opacity-5 lg:!hidden;
        p {
            @apply text-xl font-semibold opacity-40;
        }
        button {
            @apply h-5 w-5;
            svg {
                @apply mx-auto block h-4 w-4 opacity-40;
            }
        }
    }
    &__item,
    &__item-dropdown button {
        @apply h-11 py-2 text-lg font-semibold uppercase text-gray-600 text-inherit lg:h-auto lg:px-3 lg:text-sm lg:capitalize lg:text-white;
    }

    &__item-dropdown {
        &.general-links {
            @apply hidden lg:block;
        }
    }

    &__button {
        @apply block h-14 w-12 flex-none lg:hidden lg:h-16 lg:w-auto;

        span {
            @apply mx-auto block h-1 w-8 transform rounded-full bg-white transition-all;
            &::before,
            &::after {
                @apply block h-1 transform rounded-full bg-white transition-all;
                content: '';
            }
            &::before {
                @apply w-8 -translate-y-2.5;
            }
            &::after {
                @apply w-5 translate-y-1.5;
            }
        }

        /**
        
        &.is-open {
            span {
                @apply w-0;
                &::before {
                    @apply -translate-x-5 translate-y-0 -rotate-45;
                }
                &::after {
                    @apply w-8 -translate-x-5 -translate-y-1 rotate-45;
                }
            }
        }
        
        
        */
    }
}
</style>
