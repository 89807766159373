<template>
    <div class="header-headband-links">
        <ul v-if="headband" class="header-headband-links__list">
            <li class="header-headband-links__item">
                <div class="header-headband-links__title">
                    <img :src="$assets.primary.offerPercent" alt="icon" />
                    <p>{{ $lang.components.microArcLinks.discounts }}</p>
                </div>
            </li>
            <li v-for="(link, index) in headband" :key="index" class="header-headband-links__item">
                <a
                    v-if="link.external"
                    :href="link.url"
                    class="header-headband-links__link"
                    :class="{ 'text-site-primary': link.colour }"
                >
                    <p>{{ link.title }}</p>
                </a>
                <NuxtLink
                    no-prefetch
                    v-else
                    :to="link.url"
                    class="header-headband-links__link"
                    :class="{ 'text-site-primary': link.colour }"
                >
                    <p>{{ link.title }}</p>
                </NuxtLink>
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import type { Models } from '~/types/models'

export default defineComponent({
    name: 'HeaderHeadband',
    props: {
        headband: {
            type: Object as PropType<Models.Link[]>,
        },
    },
    data() {
        const { $lang } = useNuxtApp()
        return {
            $lang,
            skeletons: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        }
    },
})
</script>

<style lang="postcss" scoped>
.header-headband-links {
    @apply w-full overflow-y-hidden bg-white;
    height: var(--headband-height, 0rem);
    &__list {
        @apply container flex list-none items-center space-x-4 overflow-x-auto px-3 lg:max-w-5xl;
    }
    &__item {
        @apply flex flex-none items-center justify-center;
        height: var(--headband-height, 0rem);
    }
    &__skeleton {
        @apply h-5 flex-none animate-pulse rounded-md bg-gray-200;
        &:nth-child(1n) {
            @apply w-20;
        }
        &:nth-child(2n) {
            @apply w-16;
        }
        &:nth-child(3n) {
            @apply w-24;
        }
    }
    &__title,
    &__link {
        @apply flex items-center space-x-1.5 whitespace-nowrap lg:hover:text-site-primary lg:hover:underline;
        &:first-of-type {
            @apply no-underline;
        }
        i,
        img {
            @apply block h-3 w-3;
        }
        p {
            @apply text-sm text-black;
        }
    }
    &__link {
        @apply lg:hover:underline;
        p {
            @apply lg:hover:underline;
        }
    }

    &__title {
        img {
            @apply h-5 w-5;
        }
        p {
            @apply text-sm font-semibold text-site-primary;
        }
    }
}
</style>
